<template>
  <div class="home"
       v-if="isNaN(home)">
    <NewsLink v-if="featuredNews"
              :news="featuredNews" />
    <IntroImage :imgbg="home_page.intro_image"
                :imgfg="home_page.intro_overlay_image" />
    <MakeItEasy />
    <AnimationContainer :animations="home_page.home_animations">
      <template v-slot:before>
        <h3 class="inGrey">{{ home_page.animation_container_title }}</h3>
      </template>
      <template v-slot:after>
        <div class="inGrey">
          <GreenLink :to="localeRoute('vorteile')">{{ home_page.animation_container_button }}</GreenLink>
        </div>
      </template>
    </AnimationContainer>
    <HomeText :title="home_page.block_text_title"
              :text="home_page.block_text" />

    <ExtraContent />
    <h2 class="FAQ">{{ home_page.faq_section_title }}</h2>
    <SlideList :list="home_page.home_faq" />
    <GreenLink :to="home_page.faq_section_url"
               class="faq_button">{{ home_page.faq_section_button }}</GreenLink>

    <TestimonialContainer :testimonials="home_page.home_testimonials" />
    <Register />
    <TestApps :play="home_page.app_link_play" :ios="home_page.app_link_appstore" />
    <Collaborators :title="colabList.title"
                   :collaborators="colabList.logos"
                   v-for="colabList in home_page.colaborators" />
    <NewsletterSignup />
  </div>
</template>

<script>
// @ is an alias to /src
import AnimationContainer from '../components/general/AnimationContainer.vue';
import Collaborators from '../components/home/Collaborators.vue';
import TestimonialContainer from '../components/home/TestimonialContainer.vue';
import IntroImage from "@/components/home/IntroImage"
import NewsletterSignup from '../components/general/NewsletterSignup.vue';
import HomeText from '../components/home/HomeText.vue';
import SlideList from '../components/general/SlideList.vue';

import Register from '../components/general/Register.vue';
import TestApps from '../components/general/TestApps.vue';
import MakeItEasy from '../components/home/MakeItEasy.vue';
import NewsLink from '../components/home/NewsLink.vue';

import { mapState } from 'vuex';
import ExtraContent from '../components/home/ExtraContent.vue';
export default {
  name: 'Home',
  mounted() {
  },
  components: {
    AnimationContainer,
    IntroImage,
    TestimonialContainer,
    Collaborators,
    NewsletterSignup,
    HomeText,
    SlideList,
    Register,
    TestApps,
    MakeItEasy,
    NewsLink,
    ExtraContent
  },
  computed: {
    ...mapState(["home"]),
    home_page() {
      return this.home[this.currLocale];
    },
    featuredNews() {
      if (this.home_page.news)
        return this.home_page.news.find(e => e.show_on_home == "on")
      else return false
    }
  }
}
</script>

<style lang="scss"
       scoped>
      .home {
        @include defaultFontSize;
      }

      .inGrey {

        text-align: center;
      }

      h3.inGrey {
        margin-top: 0px;
        margin-bottom: 37px;
      }

      h2 {
        margin: 0px 0px 25px 0px;
        padding: 0px;
        text-align: center;
      }

      h2.FAQ {
        margin-bottom: 45px;
      }

      .faq_button {
        margin: 38px auto 222px auto;
      }

      @media screen and (max-width: $breakpoint) {
        .faq_button {
          margin-bottom: 93px;
        }
      }
    </style>
