<template>
    <router-link class="newsLink"
                 :style="{ '--hover': news.color_on_home, '--base': news.color_on_home_base }"
                 :to="localeRoute('about') + '#news' + news.slug">
        <padder class="newsLinkFlex">
            <div class="imgPos"
                 v-if="news.show_image_on_home">
                <div class="imgCont">
                    <Image :img="news.home_image || news.featured_image"
                           sizes="100px" />
                </div>
            </div>
            <div class="textCont">
                <div class="textPlacer">
                    {{ news.home_title }}
                </div>
                <div class="hiddenText">
                    {{ news.home_title }}
                </div>
            </div>
        </padder>


    </router-link>
</template>

<script>
export default {
    props: ["news"]
}
</script>

<style lang="scss">
.newsLink {
    min-height: 90px;
    color: white;
    text-align: left;

    .newsLinkFlex {
        display: flex;
        min-height: 90px;
        gap: 13px;
        justify-content: center;
        background-color: var(--base);
        transition: background-color 400ms;
    }

    &:hover {
        .newsLinkFlex {
            background-color: var(--hover);
        }
    }

    .textCont {
        position: relative;
        padding: 10px 0px;

        .textPlacer {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            @include bigFontSize;
            font-weight: 500;
        }

        .hiddenText {
            visibility: hidden;
            @include bigFontSize;
            font-weight: 500;
        }
    }

    .imgPos {
        position: relative;
        width: 70px;
        flex-basis: 70px;
        flex-shrink: 0;
    }

    .imgCont {
        width: 70px;
        height: 70px;
        border-radius: 3px;
        overflow: hidden;
        position: absolute;
        top: 10px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}


@media screen and (max-width: $breakpoint) {
    .newsLink .textCont {

        .textPlacer,
        .hiddenText {
            @include defaultFontSize;
        }
    }
}
</style>