<template>
    <div id="newsContainer">
        <padder big>
            <anchor hash="newsAnchor"  />
            <h3>Siresca News</h3>
            <div id="newsList">
                <template v-for="newsEl in news">
                    <anchor :hash="'news'+newsEl.slug" style="margin-bottom: 20px;" />
                    <div class="newsEl">
                        <div class="imageContainer">
                            <Image :img="newsEl.featured_image"
                                   sizes="(max-width:925px) 100vw, 210px" />
                        </div>
                        <div class="textContainer">
                            <h4>{{ newsEl.title }}</h4>
                            <div class="newsText"
                                 v-html="newsEl.content" />
                        </div>
                    </div>
                </template>
            </div>
        </padder>

    </div>
</template>

<script>
export default {
    props: ["news"]
}
</script>

<style lang="scss" scoped>
h3 {
    @include bigFontSize;
}

#newsContainer {
    width: $reduced-width;
    max-width: 100%;
    margin: 0px auto;
    margin-top: 146px;
    margin-bottom: 146px;
}

.newsEl {
    display: grid;
    grid-template-columns: 210px 1fr;
    gap: 30px;
    margin-bottom: 17px;
}

.imageContainer {
    width: 210px;
    aspect-ratio: 1/1;
    border-radius: 20px;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}


h4 {
    @include titleFontSize; 
    font-weight: 500;
    margin: 0px;
    margin-bottom: 10px;
}

.newsText {
    @include defaultFontSize;
}

@media screen and (max-width: $breakpoint) {
    #newsContainer {
        margin-top: 99px;
        margin-bottom: 99px;
    }

    .newsEl {
        grid-template-columns: 1fr;
        gap: 10px;
    }

    .imageContainer {
        width: 100%;
    }

    h4 {
        @include bigFontSize;
    }

    .newsText {
        font-size: 1.4rem;
    }
}
</style>