<template>
  <SubNavigation :sections="generateSections" />
  <div id="about"
       class="navPadding">
    <anchor hash="about_title" />
    <TextPart :pretitle="about_page.intro_text_pretitle"
              :title="about_page.intro_text_title"
              :text="about_page.intro_text_text" />
    <Partners :partners="about_page.roles" />
    <News :news="about_page.news" />
    <anchor hash="partnerAnchor"
            style="margin-bottom: 20px" />
    <Collaborators :title="colabList.title"
                   :collaborators="colabList.logos"
                   v-for="colabList in about_page.colaborators" />
  </div>
</template>

<script>
import Collaborators from '../components/home/Collaborators.vue';
import Partners from '../components/about/Partners.vue';
import News from '../components/about/News.vue';
import { mapState } from 'vuex';
export default {
  mounted() {
    // var hash = window.location.hash.slice(1);
    // if (hash) {
    //   console.log(hash)
    //   this.$nextTick(() => {
    //     window.location.href = "#" + hash;
    //   })
    // }
    console.log(this.about_page.colaborators)
  },
  components: { Collaborators, Partners, News },
  computed: {
    ...mapState(["about"]),
    about_page() {
      return this.about[this.currLocale];
    },
    generateSections() {
      return [
        {
          ref: "about_title",
          text: this.about_page.header_1
        },
        {
          ref: "newsAnchor",
          text: this.about_page.header_2
        },
        {
          ref: "partnerAnchor",
          text: this.about_page.header_3
        },
      ]
    }
  }

}
</script>
